import { PatientFilter } from '../../models/patient.models';

export default function PatientReducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_FILTER':
      sessionStorage.setItem('_patient_filter', JSON.stringify(action.payload));

      return {
        ...state,
        patientFilter: action.payload,
      };

    case 'CLEAR':
      sessionStorage.removeItem('_patient_filter');
      return {
        patientFilter: new PatientFilter(),
      };

    default:
      return state;
  }
}
