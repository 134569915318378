import { useContext, useState, useRef, MouseEvent, CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Navbar } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
import Notifications from 'src/components/notifications/Notifications.component';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import ParseService from 'src/core/services/parse.service';
import AppContext from 'src/core/context/global/AppContext';
import { can, hideFeature, isParseServerEnabled } from 'src/core/utils/global.utils';
import { colors } from 'src/assets/styles/theme.style';

interface ComponentProps {
  notifications: any;
  handleAddUserClick: (item: any) => void;
  handleSearch: (item: string) => void;
}

const GroupUsersHeader = (props: ComponentProps) => {
  const { t } = useTranslation();
  const { structure, abilities, config } = useContext(AppContext);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [showNotifications, setShowNotifications] = useState(false);
  const [unseenNotificationsCount, setUnseenNotificationsCount] = useState(0);
  const handleSearchClick = () => {
    props.handleSearch(searchKeyword);
  };

  const toggleNotificationList = () => {
    showNotifications == false && isParseServerEnabled(config) && ParseService.setNotificationsSeen(structure.id);
    setShowNotifications(!showNotifications);
    setUnseenNotificationsCount(0);
    // mark notifications as seen
  };
  useEffect(() => {
    if (props.notifications?.length) {
      const unseenNotifications = props.notifications.filter((notification: any) => notification.seen === 0);
      setUnseenNotificationsCount(unseenNotifications.length);
    }
  }, [props.notifications]);

  return (
    <>
      <Navbar expand='lg' style={styles.navBar}>
        <Navbar.Brand href='#' style={styles.navBarBrand}>
          {t('user.users')}
        </Navbar.Brand>
        <span style={{ fontSize: 22, fontWeight: '400' }}>|</span>
        <Navbar.Brand style={{ marginLeft: 16, fontWeight: '600' }}>{structure.name}</Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Collapse id='navbarScroll'>
          <Nav className='me-auto my-2 my-lg-0' style={{ maxHeight: '100px' }} navbarScroll></Nav>
          <div className='d-flex ml-auto'>
            {' '}
            <Grid container spacing={1}>
              <Grid item>
                <Paper elevation={0} style={styles.searchBar}>
                  <InputBase
                    placeholder={t('common.search')}
                    type={'search'}
                    value={searchKeyword}
                    onChange={e => {
                      setSearchKeyword(e.target.value);
                      props.handleSearch(e.target.value);
                    }}
                    aria-label='Search'
                    sx={{
                      flex: 1,
                      paddingLeft: 1,
                      color: '#7786A4',
                      textTransform: 'capitalize',
                      height: '100%',
                    }}
                  />
                  <IconButton type='submit' onClick={handleSearchClick} style={styles.searchButton}>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item>
                <Button
                  hidden={can(structure.role, ['add_user'], abilities)}
                  onClick={props.handleAddUserClick}
                  variant='contained'
                  startIcon={<AddCircleOutlineIcon />}
                  disableElevation
                  style={{ ...styles.button, ...styles.darkButton }}
                >
                  {t('user.addUser')}
                </Button>
              </Grid>
              {!hideFeature && (
                <Grid item>
                  <IconButton
                    aria-label='notification'
                    color='primary'
                    style={{ ...styles.button, ...styles.lightButton }}
                    onClick={() => {
                      toggleNotificationList();
                    }}
                  >
                    <Badge color='primary' badgeContent={unseenNotificationsCount}>
                      <NotificationsOutlinedIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  navBar: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  navBarBrand: {
    font: 'normal normal bold 20px/48px Montserrat',
    color: '#12203E',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    height: 40,
    borderRadius: '10px',
  },
  button: {
    height: 40,
    borderRadius: 10,
  },
  darkButton: {
    backgroundColor: colors.primaryColor,
  },
  lightButton: {
    backgroundColor: '#ffffff',
    color: '#7786A4',
  },
  searchButton: {
    // borderTopRightRadius: 10,
    // borderBottomRightRadius: 10,
    margin: 5,
  },
};

export default GroupUsersHeader;
