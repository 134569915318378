import { CSSProperties } from 'react';
import logo from 'src/assets/images/insight-healthcare-white.png';

interface Props {
  className?: string;
  style?: CSSProperties;
}

const MainLogo = (props: Props) => {
  return <div className={props.className} style={{ ...componentStyle, ...props.style }}></div>;
};

const componentStyle: CSSProperties = {
  backgroundImage: `url(${logo})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '100%',
};

export default MainLogo;
