import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import AppContext from 'src/core/context/global/AppContext';
import { SideContainer } from 'src/components/containers/sideContainer/SideContainer.component';
import classNames from 'classnames';
import SiteMenu from './SiteMenu.component';
import AccountMenu from './AccountMenu.component';
import GroupMenu from './GroupMenu.component';

import './sideMenu.style.scss';
import AdminMenu from './AdminMenu.component';
import { can } from 'src/core/utils/global.utils';
import { colors } from '../../../assets/styles/theme.style';

function SideMenu() {
  const { structure, clearSession, user, abilities } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const openPage = (page: string) => {
    navigate(page);
  };

  const openTelehealth = () => {
    // Open Telehealth
  };

  const openAbout = () => {
    navigate('/about');
  };

  const signOut = () => {
    clearSession();
    navigate('/login', { replace: true });
  };

  const getItemClassName = (pathname?: string) => {
    return classNames('item', { active: location.pathname === pathname });
  };

  const root = document.documentElement;
  root.style.setProperty('--active-color', colors.primaryColor);
  root.style.setProperty('--active-shadow-color', colors.activeShadowColor);

  return (
    <SideContainer>
      {structure.type == 'site' && (
        <SiteMenu
          openPage={openPage}
          signOut={signOut}
          getItemClassName={getItemClassName}
          openTelehealth={openTelehealth}
          openAbout={openAbout}
        />
      )}
      {structure.type == 'account' && (
        <AccountMenu
          openPage={openPage}
          signOut={signOut}
          getItemClassName={getItemClassName}
          openTelehealth={openTelehealth}
          openAbout={openAbout}
        />
      )}
      {structure.type == 'group' && <GroupMenu openPage={openPage} signOut={signOut} getItemClassName={getItemClassName} />}
      {structure?.type === undefined && can(user.role, ['view_ekare_admin_menu'], abilities) && (
        <AdminMenu openPage={openPage} signOut={signOut} getItemClassName={getItemClassName} />
      )}
    </SideContainer>
  );
}

export default SideMenu;
