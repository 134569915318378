export default function GroupReducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_SELECTED_ACCOUNT_ID':
      sessionStorage.setItem('_selected_account_id', action.payload);

      return {
        ...state,
        selectedAccountId: action.payload,
      };

    default:
      return state;
  }
}
