export class AlertDialogProps {
  open?: boolean;
  title?: any;
  content?: any;
  closeLabel?: string;
  confirmLabel?: string;

  onConfirm?: () => Promise<boolean | undefined> | boolean;

  constructor(state?: AlertDialogProps | any) {
    this.open = Boolean(state?.open);
    this.title = state?.title;
    this.content = state?.content;
    this.closeLabel = state?.closeLabel;
    this.confirmLabel = state?.confirmLabel;
    this.onConfirm = state?.onConfirm;
  }
}
