import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Routes from 'src/routes';
import Parse from 'parse';
import { ThemeProvider } from '@mui/material/styles';
import { themes } from 'src/assets/styles/mui.theme';
import AlertDialog from 'src/components/dialogs/AlertDialog.component';
import AppContext from 'src/core/context/global/AppContext';
import { auto_logout_delay, instance, ParseAppID, ParseServerURL } from './config';

import 'src/assets/locales/i18n';
import 'src/assets/styles/global.style.scss';
import { toggleZendeskWidget } from './core/utils/global.utils';

// Initialize Parse server
// TODO: Get these params from API config
Parse.initialize(ParseAppID);
Parse.serverURL = ParseServerURL;

function App() {
  const { alertDialogProps, setAlertDialogProps, clearSession } = useContext(AppContext);
  const [theme, setTheme] = useState(themes[instance]);
  const handleCloseAlertDialog = () => {
    setAlertDialogProps({});
  };

  const handleConfirmAlertDialog = async () => {
    (await alertDialogProps.onConfirm()) && setAlertDialogProps();
  };

  const signOut = () => {
    clearSession();
  };

  useEffect(() => {
    setTheme(themes[instance]);
  }, [instance]);

  useEffect(() => {
    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer as NodeJS.Timeout);
      inactivityTimer = setTimeout(() => {
        signOut();
      }, auto_logout_delay);
    };

    let inactivityTimer: NodeJS.Timeout;

    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);

    resetInactivityTimer();

    return () => {
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
    };
  }, []);

  useLayoutEffect(() => {
    toggleZendeskWidget(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
      {alertDialogProps && (
        <AlertDialog
          open={alertDialogProps.open}
          title={alertDialogProps.title}
          content={alertDialogProps.content}
          onClose={handleCloseAlertDialog}
          onConfirm={handleConfirmAlertDialog}
          closeLabel={alertDialogProps.closeLabel}
          confirmLabel={alertDialogProps.confirmLabel}
        />
      )}
    </ThemeProvider>
  );
}

export default App;
