/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useReducer } from 'react';
import { PatientFilter } from '../../models/patient.models';
import PatientReducer from './PatientReducer';

interface Context {
  patientFilter: PatientFilter;
  setPatientFilter: (filter: PatientFilter) => void;
  clear: () => void;
}

const initialState: Context = {
  patientFilter: new PatientFilter(JSON.parse(sessionStorage.getItem('_patient_filter') || '{}')),
  setPatientFilter: () => {},
  clear: () => {},
};

const PatientContext = createContext(initialState);

export const PatientContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(PatientReducer, initialState);

  const setPatientFilter = (filter: PatientFilter) => {
    dispatch({
      type: 'SET_FILTER',
      payload: filter,
    });
  };

  const clear = () => {
    dispatch({ type: 'CLEAR' });
  };

  return (
    <PatientContext.Provider
      value={{
        patientFilter: state.patientFilter,
        setPatientFilter,
        clear,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export default PatientContext;
