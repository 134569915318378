// import { APP_ID } from '../../../../../config';

export default {
  signIn: require('./signIn.json'),
  forgotPassword: require('./forgotPassword.json'),
  layout: require('./layout.json'),
  validate: require('./validate.json'),
  common: require('./common.json'),
  settings: require('./settings.json'),
  appIntro: require('./appIntro.json'),
  about: require('./about.json'),
  patient: require('./patient.json'),
  userManagement: require('./userManagement.json'),
  // 'wound': APP_ID === 'WoundWorks' ? require('./WoundWorks-wound.json') : require('./wound.json'),
  wound: require('./wound.json'),
  alertMessage: require('./alertMessage.json'),
  riskFactor: require('./riskFactor.json'),
  form: require('./form.json'),
  ordering: require('./ordering.json'),
  generalAssessment: require('./generalAssessment.json'),
  passwordManagement: require('./passwordManagement.json'),
  notification: require('./notification.json'),
  assessment: require('./assessment.json'),
  account: require('./account.json'),
  site: require('./site.json'),
  menu: require('./menu.json'),
  group: require('./group.json'),
  user: require('./user.json'),
};
