/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import AppContext from 'src/core/context/global/AppContext';
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import { User } from 'src/core/models/user.models';
import { ComponentLoading } from 'src/components/loading/Loading.component';
import { contactEmailPattern, contactPhonePattern, FormMode, namePattern } from 'src/core/utils/global.utils';

interface ComponentProps {
  show: boolean;
  formUser: any;
  onClose: () => void;
  onSave: (formData: any, type: FormMode) => void;
  onSaveLoading: boolean;
}

const GroupUsersForm = (props: ComponentProps) => {
  const { structure } = useContext(AppContext);
  const { show, onClose, onSave, onSaveLoading, formUser } = props;
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const { t } = useTranslation();

  const initialUserProfile = {
    ...new User(formUser),
    clinic_id_clinic: formUser?.clinic_id_clinic ? formUser.clinic_id_clinic : structure.id,
    confirm_email: formUser?.confirm_email ? formUser.confirm_email : undefined,
    system_role: formUser?.system_role ? formUser.system_role : 'class_admin',
    clinical_role: formUser?.clinical_role ? formUser.clinical_role : 'Physician',
    email: formUser?.email ? formUser.email : '',
    status: formUser?.status ? formUser.status : '1',
    site_user: false,
    first_name: formUser?.first_name ? formUser.first_name : '',
    last_name: formUser?.last_name ? formUser.last_name : '',
    phone: formUser?.phone ? formUser.phone : '',
    username: formUser?.username ? formUser.username : '',
  };

  const [userProfile, setUserProfile] = useState(initialUserProfile);
  const [isSave, setIsSave] = useState(false);

  const selectedRoles = [
    { value: 'class_admin', label: t('group.class_admin') },
    { value: 'class_user', label: t('group.class_user') },
  ];

  const genericHandleChange = (key: string, newValue: string) => {
    const newData: any = { ...userProfile };
    newData[key] = newValue;
    setUserProfile(newData);
  };
  useEffect(() => {
    setIsNewUser(!formUser);
  }, []);

  const isFormValid = () => {
    const isValid =
      userProfile.first_name?.trim() !== '' &&
      userProfile.last_name?.trim() !== '' &&
      userProfile.username?.trim() !== '' &&
      userProfile.email?.trim() !== '' &&
      userProfile.email === userProfile.confirm_email &&
      contactEmailPattern.test(userProfile.email) &&
      namePattern.test(userProfile.first_name) &&
      namePattern.test(userProfile.last_name) &&
      namePattern.test(userProfile.username);

    return isValid;
  };

  const onSaveUser = () => {
    setIsSave(false);
    if (isFormValid()) {
      onSave(userProfile, isNewUser ? FormMode.Add : FormMode.Edit);
    } else {
      setIsSave(true);
    }
  };
  return (
    <Modal size='lg' show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isNewUser ? t('common.add') : t('common.edit')} {t('user.User')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {onSaveLoading ? (
          <ComponentLoading style={{ margin: '100px' }} />
        ) : (
          <Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Alert className='alert-box' variant='outlined' severity='info'>
                  {t('validate.requiredFields')}
                </Alert>
                <Box className='tab-body' mt={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <label className='input-label'>
                        {t('userManagement.firstName')} {'  *'}
                      </label>
                      <TextField
                        value={userProfile.first_name}
                        onChange={event => {
                          genericHandleChange('first_name', event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        helperText={
                          isSave &&
                          (userProfile.first_name === undefined ||
                            userProfile.first_name === '' ||
                            !namePattern.test(userProfile.first_name))
                            ? userProfile.first_name === ''
                              ? t('validate.firstName_required')
                              : t('validate.firstName_patternMsg')
                            : ''
                        }
                        error={
                          isSave &&
                          (userProfile.first_name === undefined ||
                            userProfile.first_name === '' ||
                            !namePattern.test(userProfile.first_name))
                        }
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className='input-label'>
                        {t('userManagement.lastName')} {'  *'}
                      </label>
                      <TextField
                        value={userProfile.last_name}
                        onChange={event => {
                          genericHandleChange('last_name', event.target.value);
                        }}
                        color={userProfile.last_name ? 'success' : 'error'}
                        variant='outlined'
                        fullWidth
                        helperText={
                          isSave &&
                          (userProfile.last_name === undefined || userProfile.last_name === '' || !namePattern.test(userProfile.last_name))
                            ? userProfile.last_name === ''
                              ? t('common.search')
                              : t('validate.lastName_patternMsg')
                            : ''
                        }
                        error={
                          isSave &&
                          (userProfile.last_name === undefined || userProfile.last_name === '' || !namePattern.test(userProfile.last_name))
                        }
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className='input-label'>
                        {t('user.userName')}
                        {'  *'}
                      </label>
                      <TextField
                        value={userProfile.username}
                        onChange={event => {
                          genericHandleChange('username', event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        disabled={!isNewUser}
                        helperText={
                          isSave &&
                          (userProfile.username === undefined || userProfile.username === '' || !namePattern.test(userProfile.username))
                            ? userProfile.username === ''
                              ? t('validate.common.username_required')
                              : t('group.username_patternMsg')
                            : ''
                        }
                        error={
                          isSave &&
                          (userProfile.username === undefined || userProfile.username === '' || !namePattern.test(userProfile.username))
                        }
                        size='small'
                      />
                    </Grid>

                    <>
                      <Grid item xs={12}>
                        <label className='input-label'>
                          {t('userManagement.email')} {'  *'}
                        </label>
                        <TextField
                          value={userProfile.email}
                          onChange={event => {
                            genericHandleChange('email', event.target.value);
                          }}
                          variant='outlined'
                          fullWidth
                          helperText={
                            isSave &&
                            (!contactEmailPattern.test(userProfile.email) || userProfile.email === undefined || userProfile.email === '')
                              ? userProfile.email === ''
                                ? t('validate.contactEmail_required')
                                : t('validate.validEmailMessage')
                              : ''
                          }
                          error={
                            isSave &&
                            (!contactEmailPattern.test(userProfile.email) || userProfile.email === undefined || userProfile.email === '')
                          }
                          size='small'
                        />
                        {isNewUser && (
                          <label className='input-label' style={{ marginTop: 5 }}>
                            {t('userManagement.emailVerification')}
                          </label>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label className='input-label'>
                          {t('userManagement.confirmEmail')} {'  *'}
                        </label>
                        <TextField
                          value={userProfile.confirm_email}
                          onChange={event => {
                            genericHandleChange('confirm_email', event.target.value);
                          }}
                          variant='outlined'
                          fullWidth
                          color={userProfile.email !== userProfile.confirm_email ? 'error' : 'success'}
                          helperText={
                            isSave && (userProfile.confirm_email === undefined || userProfile.confirm_email === '')
                              ? t('validate.contactEmail_required')
                              : isSave && userProfile.email !== userProfile.confirm_email
                              ? t('validate.confirmEmail_patternMsg')
                              : ''
                          }
                          error={
                            isSave &&
                            (userProfile.confirm_email === undefined ||
                              userProfile.confirm_email === '' ||
                              userProfile.email !== userProfile.confirm_email)
                          }
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className='input-label'>{t('userManagement.phone')}</label>
                        <TextField
                          value={userProfile.phone}
                          onChange={event => {
                            genericHandleChange('phone', event.target.value);
                          }}
                          variant='outlined'
                          fullWidth
                          size='small'
                          helperText={
                            userProfile.phone !== '' && !contactPhonePattern.test(userProfile.phone)
                              ? t('validate.contactPhone_patternMsg')
                              : ''
                          }
                          error={userProfile.phone !== '' && !contactPhonePattern.test(userProfile.phone)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className='input-label'>{t('userManagement.clinicalRole')}</label>
                        <Select
                          size='small'
                          value={userProfile.clinical_role !== undefined ? userProfile.clinical_role : 'Physician'}
                          onChange={event => {
                            genericHandleChange('clinical_role', event.target.value);
                          }}
                          fullWidth
                        >
                          <MenuItem value={'Physician'}>{t('userManagement.physician')}</MenuItem>
                          <MenuItem value={'Nurse'}>{t('userManagement.nurse')}</MenuItem>
                          <MenuItem value={'Resident'}>{t('userManagement.resident')}</MenuItem>
                          <MenuItem value={'Clinical Technician'}>{t('userManagement.clinicalTechnician')}</MenuItem>
                          <MenuItem value={'Administrator'}>{t('userManagement.administrator')}</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item xs={12}>
                        <label className='input-label'>{t('userManagement.systemRole')}</label>
                        <Select
                          value={userProfile.system_role}
                          size='small'
                          onChange={event => {
                            genericHandleChange('system_role', event.target.value);
                          }}
                          fullWidth
                        >
                          {selectedRoles.map((el, index) => {
                            return (
                              <MenuItem key={index} value={el.value}>
                                {el.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    </>
                  </Grid>

                  <Grid item xs={12} pt={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size='medium'
                          checked={userProfile.status === '1'}
                          onChange={event => {
                            const newValue = event.target.checked ? '1' : '0';
                            genericHandleChange('status', newValue);
                          }}
                          name={userProfile.status}
                        />
                      }
                      label={t('common.active')}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Divider variant='fullWidth' />
            </Grid>
            <Box className='tab-footer' style={{ marginTop: 20 }}>
              <Box className='action-buttons'>
                <Button variant='contained' color='secondary' disableElevation onClick={onClose}>
                  {t('common.cancel')}
                </Button>
                <Button variant='contained' disableElevation onClick={onSaveUser}>
                  {t('common.save')}
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GroupUsersForm;
