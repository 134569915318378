/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useReducer } from 'react';
import WoundReducer from './WoundReducer';
import { DateRangeEnum, pdfReportSetting } from 'src/core/models/wound.models';

interface Context {
  state1: string;
  setState1: (value: string) => void;
  state2: string;
  setState2: (value: string) => void;
  state3: string;
  setState3: (value: string) => void;
}

const initialState: Context = {
  state1: 'State 1',
  setState1: () => {},
  state2: 'State 2',
  setState2: () => {},
  state3: 'State 3',
  setState3: () => {},
};

const WoundContext = createContext(initialState);

export const WoundContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(WoundReducer, initialState);

  const setState1 = (value: string) => {
    dispatch({
      type: 'SET_STATE1',
      payload: value,
    });
  };
  const setState2 = (value: string) => {
    dispatch({
      type: 'SET_STATE2',
      payload: value,
    });
  };
  const setState3 = (value: string) => {
    dispatch({
      type: 'SET_STATE3',
      payload: value,
    });
  };

  return (
    <WoundContext.Provider
      value={{
        state1: state.state1,
        setState1,
        state2: state.state1,
        setState2,
        state3: state.state1,
        setState3,
      }}
    >
      {children}
    </WoundContext.Provider>
  );
};

export default WoundContext;
