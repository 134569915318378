import React, { CSSProperties, useContext } from 'react';
import AppLogo from 'src/assets/images/logos/ekare.svg';
import { colors } from 'src/assets/styles/theme.style';
import { ReactComponent as BackIcon } from 'src/assets/images/SideContainer/icons/backIcon.svg';
import AppContext from 'src/core/context/global/AppContext';
import { AppModes } from 'src/core/models/session.models';
import { isContextSwitchWoundMode } from 'src/core/utils/global.utils';

interface Props {
  className?: string;
  children?: any;
  page?: string;
  onBack?: () => void;
}

export const SideContainer = (props: Props) => {
  const { appMode } = useContext(AppContext);
  const handleLogoDisplay = (page?: string) => {
    switch (page) {
      case 'wounds':
        return (
          <div style={style.container}>
            {!isContextSwitchWoundMode(appMode) && <BackIcon onClick={props.onBack} style={style.backIcon} />}
            <a style={style.logoContainer}>
              <img src={AppLogo} alt='logo' style={style.logo} />
            </a>
          </div>
        );
      default:
        return (
          <a style={style.logoContainer}>
            <img src={AppLogo} alt='logo' style={style.logo} />
          </a>
        );
    }
  };
  return (
    <div style={style.component} className={props.className}>
      {handleLogoDisplay(props.page)}
      <div style={style.sideContainerElements}>{props.children}</div>
    </div>
  );
};

const style: { [style: string]: CSSProperties } = {
  component: {
    width: '236px',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    position: 'absolute',
  },
  logoContainer: {
    margin: '12px 27px 12px 8px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  logo: {
    width: '129px',
  },
  sideContainerElements: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    maxHeight: '90vh',
  },
  container: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginLeft: '24px',
    maxHeight: '10vh',
  },
  backIcon: {
    width: 40,
    height: 40,
    backgroundColor: colors.sideContainer_backIcon,
    borderRadius: 5,
    padding: 8,
    margin: '8px 0px',
    cursor: 'pointer',
  },
};
