export default function WoundReducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_STATE1':
      return {
        ...state,
        state1: action.payload,
      };

    case 'SET_STATE2':
      return {
        ...state,
        state2: action.payload,
      };

    case 'SET_STATE3':
      return {
        ...state,
        state3: action.payload,
      };

    default:
      return state;
  }
}
