import React, { CSSProperties, useState } from 'react';
import './ResetForm.style.scss';
import { useTranslation } from 'react-i18next';

interface ResetFormProps {
  data: { password: string; confirmPassword: string };
  setData: (data: { password: string; confirmPassword: string }) => void;
  onSubmit: () => void;
}

const ResetForm = (props: ResetFormProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const onSubmit = (event: any) => {
    event.preventDefault();
    props.onSubmit();
  };

  const handlePasswordChange = (event: any) => {
    props.setData({ ...props.data, password: event.target.value });
  };

  const handleConfirmPasswordChange = (event: any) => {
    props.setData({ ...props.data, confirmPassword: event.target.value });
  };
  return (
    <form onSubmit={onSubmit} className='reset-form'>
      <div className='mb-3'>
        <label className='form-label'>{t('user.password')}</label>
        <input id='resetFormPassword' type='password' className='form-control' onChange={handlePasswordChange} />
      </div>
      <div className='mb-3'>
        <label className='form-label'>{t('settings.confirmPassword')}</label>
        <input id='resetFormConfirmPassword' type='password' className='form-control' onChange={handleConfirmPasswordChange} />
      </div>
      <button style={style.btn_reset} type='submit' className='btn btn-secondary'>
        {t('settings.resetPassword')}
      </button>
    </form>
  );
};

export default ResetForm;

const style: { [style: string]: CSSProperties } = {
  btn_reset: {
    width: '100%',
    padding: '0.5rem',
  },
};
