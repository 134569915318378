import { AccountPeriodOptions } from 'src/core/models/account.models';

// Helper function to calculate the end date
export const calculateEndDate = (startDate: any, period: any) => {
  if (startDate) {
    const date = new Date(startDate);
    switch (period) {
      case AccountPeriodOptions.OneMonth:
        date.setMonth(date.getMonth() + 1);
        break;
      case AccountPeriodOptions.ThreeMonths:
        date.setMonth(date.getMonth() + 3);
        break;
      case AccountPeriodOptions.SixMonths:
        date.setMonth(date.getMonth() + 6);
        break;
      case AccountPeriodOptions.OneYear:
        date.setFullYear(date.getFullYear() + 1);
        break;
      case AccountPeriodOptions.TwoYears:
        date.setFullYear(date.getFullYear() + 2);
        break;
      case AccountPeriodOptions.ThreeYears:
        date.setFullYear(date.getFullYear() + 3);
        break;
      default:
        return ''; // For custom or undefined periods, we don't calculate the end date
    }
    return date.toISOString().split('T')[0]; // Format the date to YYYY-MM-DD
  }
  return '';
};

export const convertPeriodToMonths = (period: string) => {
  switch (period) {
    case AccountPeriodOptions.OneMonth:
      return '1';
    case AccountPeriodOptions.ThreeMonths:
      return '3';
    case AccountPeriodOptions.SixMonths:
      return '6';
    case AccountPeriodOptions.OneYear:
      return '12';
    case AccountPeriodOptions.TwoYears:
      return '24';
    case AccountPeriodOptions.ThreeYears:
      return '36';
    case AccountPeriodOptions.Custom:
      // Handle custom period conversion logic if needed
      return 'custom';
    default:
      return '1'; // Default or fallback value
  }
};

export const convertMonthsToPeriod = (months: string): AccountPeriodOptions => {
  switch (months) {
    case '1':
      return AccountPeriodOptions.OneMonth;
    case '3':
      return AccountPeriodOptions.ThreeMonths;
    case '6':
      return AccountPeriodOptions.SixMonths;
    case '12':
      return AccountPeriodOptions.OneYear;
    case '24':
      return AccountPeriodOptions.TwoYears;
    case '36':
      return AccountPeriodOptions.ThreeYears;
    case 'custom':
      return AccountPeriodOptions.Custom;
    default:
      throw new Error('Invalid input for period conversion');
  }
};
