export interface Measurement {
  id_measurement?: number;
  id_sub_measurement?: number;
  id_group?: string;
  uid?: string;
  avg_depth?: string;
  maximum_depth?: number | null;
  maximum_width?: string | null;
  maximum_length?: string | null;
  perimeter?: string;
  area?: string;
  areaProgressPercentage?: number | null;
  volume?: string | null;
  date?: string;
  last_update_date?: string;
  granulation?: number;
  slough?: number;
  eschar?: number;
  data64?: string;
  fx?: number;
  cx?: number;
  cy?: number;
  orientation_angle?: number;
  taken_with_sensor?: 0 | 1;
  is_raised_wound?: 0 | 1;
  uploaded?: 0 | 1 | 2 | 3;
  is_deleted?: 0 | 1;
  format?: any;
  path?: string;
  action?: string;
  edit_measurement_reasons?: string | null;
  edit_classification_reasons?: string | null;
  redo_reasons?: string;
  quality_index?: any;
  source?: string;
  device?: string;
  measurement_created_at?: string;
  measurement_updated_at?: string;
  mrn?: any;
  integration_update_date?: string;
  structure?: string;
  assignee?: string;
  uploadType?: string;
  notes?: {
    timestamp?: string;
    user_id?: number;
    user_first_name?: string;
    user_last_name?: string;
    text?: string;
  };
  planimetry?: any;
  status?: 1 | 0 | null;
  visit_type?: any;
  visit_id?: any;
  locked?: 0 | 1 | '0' | '1';
  image_name?: string;
  individual_measurements?: Measurement[];
  image_link?: string;
  outline_link?: string;
  classification_link?: string;
  temp_classification_image?: any;
  classification?: string;
  wound_id_wound?: number;
  Wound_id_wound?: number;
  sub_measurements?: Measurement[];
  maxDepth?: string | null;
  reasons?: any;
  url?: string;
  length?: string;
  width?: string;
  meanHeightDepth?: string;
  circumference?: string;
  depthIndex?: string;
  rect_point0x?: number;
  rect_point0y?: number;
  rect_point1x?: number;
  rect_point1y?: number;
  rect_point2x?: number;
  rect_point2y?: number;
  rect_point3x?: number;
  rect_point3y?: number;
}

export class MeasurementClass {
  id_measurement: number;
  id_sub_measurement: number;
  id_group: string;
  uid: string;
  avg_depth: string;
  maximum_depth: number;
  maximum_width: string;
  maximum_length: string;
  perimeter: string;
  area: string;
  areaProgressPercentage: number;
  volume: string;
  date: string;
  last_update_date: string;
  granulation: number;
  slough: number;
  eschar: number;
  fx: number;
  cx: number;
  cy: number;
  orientation_angle: number;
  taken_with_sensor: 0 | 1;
  is_raised_wound: 0 | 1;
  uploaded: 0 | 1 | 2 | 3;
  is_deleted: 0 | 1;
  format: any;
  path: string;
  action: string;
  edit_measurement_reasons: string;
  edit_classification_reasons: string;
  redo_reasons: string;
  quality_index: string;
  source: string;
  device: string;
  measurement_created_at: string;
  measurement_updated_at: string;
  integration_update_date: string;
  structure: string;
  assignee: string;
  notes: {
    timestamp: string;
    user_id: number;
    user_first_name: string;
    user_last_name: string;
    text: string;
  };
  planimetry: any;
  status: 1 | 0;
  visit_type: any;
  visit_id: any;
  locked: 0 | 1;
  image_name: string;
  image_link: string;
  outline_link: string;
  classification_link: string;
  wound_id_wound: number;
  sub_measurements: Measurement[];

  constructor(measurement: any) {
    this.id_measurement = measurement.id_measurement;
    this.id_sub_measurement = measurement.id_sub_measurement;
    this.id_group = measurement.id_group;
    this.uid = measurement.uid;
    this.avg_depth = measurement.avg_depth;
    this.maximum_depth = measurement.maximum_depth;
    this.maximum_width = measurement.maximum_width;
    this.maximum_length = measurement.maximum_length;
    this.perimeter = measurement.perimeter;
    this.area = measurement.area;
    this.areaProgressPercentage = measurement.areaProgressPercentage;
    this.volume = measurement.volume;
    this.date = measurement.date;
    this.last_update_date = measurement.last_update_date;
    this.granulation = measurement.granulation;
    this.slough = measurement.slough;
    this.eschar = measurement.eschar;
    this.fx = measurement.fx;
    this.cx = measurement.cx;
    this.cy = measurement.cy;
    this.orientation_angle = measurement.orientation_angle;
    this.taken_with_sensor = measurement.taken_with_sensor;
    this.is_raised_wound = measurement.is_raised_wound;
    this.uploaded = measurement.uploaded;
    this.is_deleted = measurement.is_deleted;
    this.format = measurement.format;
    this.path = measurement.path;
    this.action = measurement.action;
    this.edit_measurement_reasons = measurement.edit_measurement_reasons;
    this.edit_classification_reasons = measurement.edit_classification_reasons;
    this.redo_reasons = measurement.redo_reasons;
    this.quality_index = measurement.quality_index;
    this.source = measurement.source;
    this.device = measurement.device;
    this.measurement_created_at = measurement.measurement_created_at;
    this.measurement_updated_at = measurement.measurement_updated_at;
    this.integration_update_date = measurement.integration_update_date;
    this.structure = measurement.structure;
    this.assignee = measurement.assignee;
    this.notes = measurement.notes;
    this.planimetry = measurement.planimetry;
    this.status = measurement.status;
    this.visit_type = measurement.visit_type;
    this.visit_id = measurement.visit_id;
    this.locked = measurement.locked;
    this.image_name = measurement.image_name;
    this.image_link = measurement.image_link;
    this.outline_link = measurement.outline_link;
    this.classification_link = measurement.classification_link;
    this.wound_id_wound = measurement.wound_id_wound;
    this.sub_measurements = measurement.sub_measurements;
  }
}

export class GetMeasurementsReq {
  wound?: number;
}

export class InputParamPixels {
  greenPix?: number[][];
  redPix?: number[][];
}

export class GetRedoReq {
  automatic_mode?: boolean;
  inputParamPixels?: InputParamPixels;
  is_sub?: boolean;
  meas_type?: string;
  session_id?: string;
  status?: string;
  classification_image?: string;
  type?: string;
}

export class GetRedoResp {
  outlinePix?: number[][];
  sessionId?: string;
  success?: boolean;
  classificationPerc?: any;
  classificationPix?: any;
  outlinePixMetric?: any;
  woundData?: WoundData;
  structure?: string;
  error_message?: string;
  outlinedProperly?: any;
  overlap_error?: any;
  error_key?: any;
  isColorColorEdited?: boolean;
  reClassifiedData64?: string;
}

export class WoundData {
  circumference?: number;
  length?: number;
  width?: number;
  area?: number;
  volume?: number;
  rect_point0x?: number;
  rect_point0y?: number;
  rect_point1x?: number;
  rect_point1y?: number;
  rect_point2x?: number;
  rect_point2y?: number;
  rect_point3x?: number;
  rect_point3y?: number;
  maxDepth?: number;
  maxH?: number;
  maxW?: number;
  error?: number;
  percentageStripUsed?: number;
  encirclement?: number;
  meanHeightDepth?: number;
  angleArea?: number;
  angleVolume?: number;
  noneNANelements?: number;
  error_message?: string;
}

export class GetMeasureResp {
  classificationPerc?: any;
  classificationPix?: any;
  outlinePixMetric?: any;
  session_id?: string;
  success?: boolean;
  woundData?: Measurement;
}

export enum MeasurementStructure {
  SUB = 'sub',
  AOI = 'aoi',
  EXCLUSION = 'exclusion',
  INCLUSION = 'inclusion',
  GRP = 'group',
}

export const PIXEL_COLORS = ['rgba(127, 255, 0, 255)', 'rgba(255, 0, 0, 255)', 'rgba(255, 255, 0, 255)'];
export const LINE_WIDTHS = [4.2];

export class SaveMeasurementRequest {
  measurements?: any[];
  measurements_history?: any;
  session_id?: string;
}

export enum InclusionEnum {
  Inclusion,
  Exlusion,
  AreaOfInterest,
}

export const ClassificationColors = {
  eschar: [82, 136, 162, 1],
  granulation: [218, 92, 113, 1],
  slough: [242, 190, 88, 1],
};

export interface GroupMeasurementsReq {
  measurements: (number | undefined)[];
  client_datetime: string;
}

export interface DownloadMeasurementReq {
  api_pass: string;
  patient: any;
  measurement: Measurement;
}

export class SortFilterMeasurementsReq {
  wound: number | undefined;
  type?: ('photo' | '2D' | '3DL' | '3D')[] | null;
  sort?: string = 'date';
  order?: string = 'asc';
}

export enum RedoType {
  REMEASURE = 'remeasure',
  RECLASSIFY = 'reclassify',
}
