import { useTranslation } from 'react-i18next';
import { BiArrowBack, BiBell, BiHome } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FiSettings, FiLogOut } from 'react-icons/fi';

import './sideMenu.style.scss';
import { can } from 'src/core/utils/global.utils';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { Structure } from 'src/core/models/session.models';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
}

function GroupMenu(props: Props) {
  const { openPage, signOut, getItemClassName } = props;
  const { t } = useTranslation();
  const { user, abilities, setStructure, structure } = useContext(AppContext);

  const backToGroups = () => {
    setStructure(new Structure(JSON.parse('{}')));
    openPage('/groups');
  };
  return (
    <div className='side-menu'>
      <ul className='items'>
        {can(user.role, ['view_classes'], abilities) && (
          <li className={getItemClassName('/groups')} onClick={backToGroups} style={{ width: 214, marginLeft: 10 }}>
            <BiArrowBack className='item-icon' />
            {t('menu.accountAdministration')}
          </li>
        )}
        <li className={getItemClassName('/accounts')} onClick={() => openPage('/accounts')}>
          <BiHome className='item-icon' />
          {t('menu.accounts')}
        </li>
        {/* TODO: 
         <li className={getItemClassName('/notifications')} onClick={() => openPage('/notifications')}>
          <BiBell className='item-icon' />
          {t('group.globalNotifications')}
        </li>
        <li className={getItemClassName('/reports')} onClick={() => openPage('/reports')}>
          <HiOutlineClipboardList className='item-icon' />
          {t('group.report')}
        </li>
        <li className={getItemClassName('/fm')} onClick={() => openPage('/fm')}>
          <FiSettings className='item-icon' />
          {t('group.fieldManagement')}
        </li> */}
        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <FiLogOut className='item-icon' />
            {t('menu.signOut')}
          </li>
        </div>
      </ul>
    </div>
  );
}

export default GroupMenu;
