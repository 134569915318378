export function getSystemRoleLabelKey(systemRole?: string, type?: string) {
  switch (systemRole) {
    case 'site_admin':
      return type === 'account' ? 'site.siteLevel' : 'site.siteAdmin';
    case 'account_admin':
      return 'account.accountAdmin';
    case 'site_user':
      return type === 'account' ? 'site.siteLevel' : 'site.siteUser';
    case 'site_viewer':
      return type === 'account' ? 'site.siteLevel' : 'site.siteViewer';
    case 'account_viewer':
      return 'account.accountViewer';
    case 'account_user':
      return 'account.accountUser';
    case 'site_patient':
      return type === 'account' ? 'site.siteLevel' : 'site.sitePatient';
    case 'class_user':
      return 'user.classUser';
    case 'class_admin':
      return 'user.classAdmin';

    case undefined:
      return '';
  }
  return '';
}
