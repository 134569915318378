import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';

interface SnackbarComponentProps {
  open: boolean;
  severity: AlertColor;
  message: string;
  onClose: () => void;
  autoHideDuration?: number;
}
const defaultAutoHideDuration = 5000;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='standard' {...props} />;
});

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ open, severity, message, onClose, autoHideDuration }) => {
  return (
    <>
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration ? autoHideDuration : defaultAutoHideDuration}
          onClose={onClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={(slideProps: SlideProps) => {
            return <Slide {...slideProps} direction='down' />;
          }}
        >
          <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarComponent;
