export class User {
  id: number | string;
  id_patient?: number;
  username?: string;
  clinic_id_clinic?: string | number;
  external_id?: string;
  email?: string;
  confirm_email?: string;
  status?: '0' | '1';
  first_name?: string;
  last_name?: string;
  fullname?: string;
  phone?: string;
  clinical_role?: string;
  system_role?: string;
  id_user?: string;
  patient_reminder: any;
  home_structure?: string;
  email_confirmed?: string;
  sensor_setting?: string;
  password: any;
  relations?: string;
  has_bi_access?: boolean;
  patients?: any[];
  structures?: any[];
  vip_access?: string | number;

  constructor(user: any) {
    this.id = +user?.id || 0;
    if (user) {
      this.id_patient = user.id_patient;
      this.username = user.username;
      this.clinic_id_clinic = user.clinic_id_clinic;
      this.external_id = user.external_id;
      this.email = user.email;
      this.first_name = user.first_name;
      this.last_name = user.last_name;
      this.fullname = user.fullname;
      this.clinical_role = user.clinical_role;
      this.system_role = user.system_role;
      this.phone = user.phone;
      this.status = user.status;
      this.id_user = user.id_user;
      this.patient_reminder = user.patient_reminder;
      this.home_structure = user.home_structure;
      this.email_confirmed = user.email_confirmed;
      this.sensor_setting = user.sensor_setting;
      this.password = user.password;
      this.relations = user.relations;
      this.has_bi_access = user.has_bi_access;
      this.patients = user.patients;
      this.structures = user.structures;
      this.vip_access = user.vip_access;
    }
  }
}

export class Abilities {
  roleAbilities?: roleAbilitiesType;
  subscriptionAbilities?: subscriptionAbilitiesType;
  constructor(abilities: any) {
    if (abilities) {
      this.roleAbilities = abilities.roleAbilities;
      this.subscriptionAbilities = abilities.subscriptionAbilities;
    }
  }
}

export interface subscriptionAbilitiesType {
  enterprise?: any;
  lite?: string[];
  lite_trial?: string[];
  premium?: string[];
  pro?: string[];
}

export interface roleAbilitiesType {
  account_admin?: string[];
  account_independent_reader?: string[];
  account_user?: string[];
  account_viewer?: string[];
  class_admin?: string[];
  class_user?: string[];
  ekare_admin?: string[];
  site_admin?: string[];
  site_patient?: string[];
  site_user?: string[];
  site_viewer?: string[];
}

export interface GetUsersReq {
  clinic: number;
  activeOnly: number;
  exclude_patient_users?: boolean | number;
  flag?: number;
  status?: number;
  patientBased?: number;
  appointments_responsibles?: boolean;
  load_patients?: number;
}
