import { calculateEndDate } from 'src/pages/accounts/Accounts.utils';

export interface GetAccountsReq {
  parent: string | undefined;
}

export interface Options {
  singleDatePicker: boolean;
  endDate?: string;
}

export interface AddAccountReq {
  account_number?: string;
  structure_status?: number;
  subscription_type?: string;
  storage_limit?: number;
  web?: string;
  city?: string;
  parent_id?: string;
  setting?: string;
  purpose?: string;
  period?: string;
  min?: string;
  options?: Options;
  data_access?: number;
  bi_access?: number;
  name?: string;
  address?: string;
  state_province?: string;
  country?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  start_date?: string;
  end_date?: string;
  custom_date?: CustomDate;
  status?: number;
  role?: string;
  clinical_role?: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  email2?: string;
  plan_status?: number;
  subscription_plan?: string;
}

export interface UpdateAccountReq {
  id?: string;
  action_setting?: string;
  account_number?: string;
  structure_status?: number;
  subscription_type?: string;
  storage_limit?: number;
  web?: string;
  city?: string;
  parent_id?: string;
  setting?: string;
  purpose?: string;
  period?: string;
  min?: string;
  options?: Options;
  data_access?: number;
  bi_access?: number;
  name?: string;
  address?: string;
  state_province?: string;
  country?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  start_date?: string;
  end_date?: string;
  custom_date?: CustomDate;
  plan_status?: number;
  subscription_plan?: string;
}

// Enum Definitions
export enum AccountSettingsOptions {
  HealthSystem = 'health_system',
  PharmaceuticalCompany = 'pharmaceutical_company',
  DeviceManufacturer = 'device_manufacturer',
  Hospital = 'hospital',
  Clinic = 'clinic',
  SNF = 'snf',
  Homecare = 'homecare',
  CRO = 'cro',
}

export enum AccountPurposeOptions {
  Research = 'research',
  Clinical = 'clinical',
  Other = 'other',
}

export enum AccountSubscribtionTypeOptions {
  Duration = 'duration',
  Storage = 'storage',
  Offline = 'offline',
  Lite = 'lite',
}

export enum AccountPeriodOptions {
  OneMonth = '1_month',
  ThreeMonths = '3_months',
  SixMonths = '6_months',
  OneYear = '1_year',
  TwoYears = '2_years',
  ThreeYears = '3_years',
  Custom = 'custom',
}

export enum AccountSystemRoleOptions {
  AccountAdmin = 'account_admin',
  AccountUser = 'account_user',
  AccountViewer = 'account_viewer',
  BlindedAssessor = 'blinded_assessor',
  IndependentReviewer = 'independent_reviewer_wound_closure',
}

export enum ClinicalRoleOptions {
  Physician = 'physician',
  Nurse = 'nurse',
  Resident = 'resident',
  ClinicalTechnician = 'clinicalTechnician',
  Administrator = 'administrator',
}

export interface CustomDate {
  start_date: string | undefined;
  end_date: string | undefined;
}
export type OptionsEnum =
  | AccountSettingsOptions
  | AccountPurposeOptions
  | AccountSubscribtionTypeOptions
  | AccountPeriodOptions
  | AccountSystemRoleOptions
  | ClinicalRoleOptions;

export class AccountForm {
  [key: string]: any;
  id: string;
  action_setting: string;
  account_number: string;
  account_name: string;
  address: string;
  city: string;
  state_province: string;
  country: string;
  web: string;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  settings: AccountSettingsOptions | undefined;
  purpose: AccountPurposeOptions | undefined;
  data_access: boolean;
  bi_dashboard_access: boolean;
  account_subscription_type: AccountSubscribtionTypeOptions | undefined;
  start_date_est: string | undefined;
  end_date_est: string | undefined;
  custom_date: CustomDate;
  period: AccountPeriodOptions | undefined;
  subscribtion_active: boolean;
  active?: boolean;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  confirm_email?: string;
  phone?: string;
  system_role?: AccountSystemRoleOptions;
  clinical_role?: ClinicalRoleOptions;

  constructor() {
    const initDate = new Date().toISOString().split('T')[0];
    this.id = '';
    this.action_setting = '';
    this.account_number = '';
    this.account_name = '';
    this.address = '';
    this.city = '';
    this.state_province = '';
    this.country = '';
    this.web = '';
    this.contact_name = '';
    this.contact_phone = '';
    this.contact_email = '';
    this.settings = AccountSettingsOptions.HealthSystem;
    this.purpose = AccountPurposeOptions.Research;
    this.data_access = true;
    this.bi_dashboard_access = false;
    this.account_subscription_type = AccountSubscribtionTypeOptions.Duration;
    // this.storage = '';
    // this.offline = '';
    // this.lite = '';
    this.start_date_est = initDate;
    this.end_date_est = calculateEndDate(initDate, AccountPeriodOptions.OneMonth);
    this.custom_date = {
      start_date: initDate,
      end_date: calculateEndDate(initDate, AccountPeriodOptions.OneMonth),
    };
    this.period = AccountPeriodOptions.OneMonth;
    this.subscribtion_active = true;
    this.active = true;
    this.first_name = '';
    this.last_name = '';
    this.username = '';
    this.email = '';
    this.confirm_email = '';
    this.phone = '';
    this.system_role = AccountSystemRoleOptions.AccountAdmin;
    this.clinical_role = ClinicalRoleOptions.Physician;
  }
}

export interface FieldError {
  error: boolean;
  errorMessage: string;
}

export interface FieldErrors {
  [fieldName: string]: FieldError;
}

export const StepOneFieldsKeys = [
  'account_number',
  'account_name',
  'address',
  'city',
  'state_province',
  'country',
  'web',
  'contact_name',
  'contact_phone',
  'contact_email',
  'settings',
  'purpose',
  'data_access',
  'bi_dashboard_access',
];

export const AccountRequiredFields = [
  'contact_name',
  'contact_email', // Assuming this is the Contact Email
  'contact_phone',
  'user_name',
  'state_province',
  'state_province',
  'city',
  'country',
  'address',
  'account_name',
  'first_name',
  'last_name',
  'email',
  'confirm_email',
];

export const StepFourFieldsKeys = [
  'first_name',
  'last_name',
  'username',
  'email',
  'confirm_email',
  'phone',
  'system_role',
  'clinical_role',
  'active',
];
