import { useTranslation } from 'react-i18next';
import { BiBell, BiHome } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FiSettings, FiLogOut } from 'react-icons/fi';
import { can, hideFeature } from 'src/core/utils/global.utils';

import './sideMenu.style.scss';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
}

function AdminMenu(props: Props) {
  const { openPage, signOut, getItemClassName } = props;
  const { t } = useTranslation();
  const { structure, abilities } = useContext(AppContext);

  return (
    <div className='side-menu'>
      <ul className='items'>
        <li className={getItemClassName('/groups')} onClick={() => openPage('/groups')}>
          <BiHome className='item-icon' />
          {t('menu.groups')}
        </li>
        <li className={getItemClassName('/adminNotifications')} onClick={() => openPage('/adminNotifications')}>
          <BiBell className='item-icon' />
          {t('menu.notifications')}
        </li>
        {!can(structure.role, ['export_report'], abilities) && (
          <li
            className={getItemClassName('/activeUsers')}
            onClick={() => {
              openPage('/activeUsers');
            }}
          >
            <HiOutlineClipboardList className='item-icon' />
            {t('menu.activeUsers')}
          </li>
        )}

        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <FiLogOut className='item-icon' />
            {t('menu.signOut')}
          </li>
        </div>
      </ul>
    </div>
  );
}

export default AdminMenu;
