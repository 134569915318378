/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useReducer } from 'react';
import GroupReducer from './GroupReducer';

interface Context {
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string) => void;
}

const initialState: Context = {
  selectedAccountId: undefined,
  setSelectedAccountId: () => {},
};

const GroupContext = createContext(initialState);

export const GroupContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(GroupReducer, initialState);

  const setSelectedAccountId = (selectedAccountId: string) => {
    dispatch({
      type: 'SET_SELECTED_ACCOUNT_ID',
      payload: selectedAccountId,
    });
  };

  return (
    <GroupContext.Provider
      value={{
        selectedAccountId: state.selectedAccountId,
        setSelectedAccountId: setSelectedAccountId,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export default GroupContext;
