import { AppModes, Config, Structure, User } from 'src/core/models/session.models';
import { Patient } from 'src/core/models/patient.models';
import { AlertDialogProps } from 'src/core/models/dialog.models';
import { Wound, WoundLocationsListModel } from 'src/core/models/wound.models';
import { Abilities } from 'src/core/models/user.models';
import { MeasurementClass } from 'src/core/models/measurement.models';
import { PDF_REPORT_SETTING } from 'src/core/data/wounds';

export default function AppReducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_APP_MODE':
      sessionStorage.setItem('_appMode', JSON.stringify(action.payload));
      return {
        ...state,
        appMode: action.payload,
      };
    case 'SET_APP_TO_APP':
      sessionStorage.setItem('_appToApp', JSON.stringify(action.payload));
      return {
        ...state,
        appToApp: action.payload,
      };
    case 'SET_ABILITIES':
      sessionStorage.setItem('_abilities', JSON.stringify({ ...state.abilities, ...action.payload }));
      return {
        ...state,
        abilities: new Abilities({ ...state.abilities, ...action.payload }),
      };

    case 'SET_CONFIG': {
      const newConfig = { ...state.config, ...action.payload };
      sessionStorage.setItem('_config', JSON.stringify(newConfig));

      // Store LDAP config in local storage to persist it after clearing session in logout or auto logout
      // LDAP config is needed so the user can login again
      if (newConfig.ldapAuth) {
        localStorage.setItem(
          'ldapConfig',
          JSON.stringify({
            ldapAuth: newConfig.ldapAuth,
            ldapDomains: newConfig.ldapDomains,
          }),
        );
      }

      return {
        ...state,
        config: new Config(newConfig),
      };
    }

    case 'SET_USER':
      sessionStorage.setItem('_user', JSON.stringify({ ...state.user, ...action.payload }));
      return {
        ...state,
        user: new User({ ...state.user, ...action.payload }),
      };

    case 'SET_STRUCTURE':
      sessionStorage.setItem('_structure', JSON.stringify({ ...state.structure, ...action.payload }));
      return {
        ...state,
        structure: new Structure({ ...state.structure, ...action.payload }),
      };

    case 'SET_PATIENT':
      sessionStorage.setItem('_patient', JSON.stringify({ ...state.patient, ...action.payload }));
      return {
        ...state,
        patient: new Patient({ ...state.patient, ...action.payload }),
      };

    case 'SET_WOUND':
      sessionStorage.setItem('_wound', JSON.stringify({ ...state.wound, ...action.payload }));
      return {
        ...state,
        wound: new Wound({ ...state.wound, ...action.payload }),
      };
    case 'SET_PDF_REPORT_SETTING':
      localStorage.setItem(PDF_REPORT_SETTING, JSON.stringify(action.payload));
      return {
        ...state,
        pdfReportSetting: action.payload,
      };
    case 'CLEAR_SESSION':
      sessionStorage.clear();
      return {
        appMode: AppModes.Standard,
        appToApp: false,
        config: new Config({ ...JSON.parse(localStorage.getItem('ldapConfig') || '{}') }),
        user: new User({}),
        structure: new Structure({}),
        patient: new Patient({}),
        wound: new Wound({}),
        selectedAccount: new Structure({}),
        selectedSite: new Structure({}),
      };

    case 'SET_ALERT_DIALOG_PROPS':
      return {
        ...state,
        alertDialogProps: new AlertDialogProps(action.payload),
      };

    case 'SET_LAST_MEASUREMENT':
      sessionStorage.setItem('_lastMeasurement', JSON.stringify({ ...state.lastMeasurement, ...action.payload }));
      return {
        ...state,
        lastMeasurement: new MeasurementClass({ ...state.lastMeasurement, ...action.payload }),
      };
    case 'CLEAR_LAST_MEASUREMENT':
      sessionStorage.setItem('_lastMeasurement', JSON.stringify({}));
      return {
        ...state,
        lastMeasurement: new MeasurementClass({}),
      };
    case 'SET_SELECTED_SITE':
      sessionStorage.setItem('_selectedSite', JSON.stringify(action.payload));
      return {
        ...state,
        selectedSite: new Structure(action.payload),
      };
    case 'SET_SELECTED_ACCOUNT':
      sessionStorage.setItem('_selectedAccount', JSON.stringify(action.payload));
      return {
        ...state,
        selectedAccount: new Structure(action.payload),
      };
    case 'SET_WOUND_LOCATIONS':
      sessionStorage.setItem('_woundLocations', JSON.stringify({ ...state.woundLocations, ...action.payload }));
      return {
        ...state,
        woundLocations: new WoundLocationsListModel({ ...state.woundLocations, ...action.payload }),
      };
    default:
      return state;
  }
}
