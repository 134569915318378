import { createTheme } from '@mui/material/styles';
import { AppInstance } from 'src/core/utils/instance.utils';

const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#3ABBCC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F2F5FA',
      dark: '#D6DAE3',
      contrastText: '#7786A4',
    },
    success: {
      main: '#e7f7f9',
      dark: '#b9e5eb',
      light: '#3ABBCC',
      contrastText: '#3ABBCC',
    },
  },
});

const crTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#bf87ed',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F2F5FA',
      dark: '#D6DAE3',
      contrastText: '#7786A4',
    },
    success: {
      main: '#e7f7f9',
      dark: '#b9e5eb',
      light: '#bf87ed',
      contrastText: '#bf87ed',
    },
  },
});

export const themes = {
  [AppInstance.IS]: defaultTheme,
  [AppInstance.EU]: defaultTheme,
  [AppInstance.UK]: defaultTheme,
  [AppInstance.SG]: defaultTheme,
  [AppInstance.SINGHEALTH]: defaultTheme,
  [AppInstance.NATROX]: defaultTheme,
  [AppInstance.CR]: crTheme,
};
