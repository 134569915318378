import { useContext, useEffect } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { useTranslation } from 'react-i18next';
import { VscGraphLine } from 'react-icons/vsc';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FiUsers, FiSettings, FiHelpCircle, FiLogOut, FiInfo } from 'react-icons/fi';
import { BsTelephonePlus } from 'react-icons/bs';
import { BiArrowBack, BiHome } from 'react-icons/bi';

import './sideMenu.style.scss';
import { can, hideFeature } from 'src/core/utils/global.utils';
import { isSinghealthInstance } from 'src/core/utils/instance.utils';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
  openTelehealth: any;
  openAbout: any;
}

function AccountMenu(props: Props) {
  const { structure } = useContext(AppContext);
  const { openPage, signOut, getItemClassName, openTelehealth, openAbout } = props;
  const { t } = useTranslation();
  const { user, setStructure, abilities } = useContext(AppContext);

  const backToAccounts = () => {
    if (user.structures && user.structures.length > 0) {
      setStructure(user.structures[0]);
      openPage('/accounts');
    }
  };

  return (
    <div className='side-menu'>
      <ul className='items'>
        {user.structures && can(user.structures[0].role, ['view_accounts'], abilities) && (
          <li className={getItemClassName('/accounts')} onClick={backToAccounts} style={{ width: 214, marginLeft: 10 }}>
            <BiArrowBack className='item-icon' />
            {user.structures?.[0]?.type === 'group' ? t('menu.groupAdministration') : t('menu.accountAdministration')}
          </li>
        )}
        {hideFeature && (
          <li className={getItemClassName('/dashboard')} onClick={() => openPage('/dashboard')}>
            <VscGraphLine className='item-icon' />
            {t('menu.dashboard')}
          </li>
        )}
        <li className={getItemClassName('/sites')} onClick={() => openPage('/sites')}>
          <BiHome className='item-icon' />
          {t('menu.sites')}
        </li>
        <li className={getItemClassName('/users')} onClick={() => openPage('/users')}>
          <FiUsers className='item-icon' />
          {t('menu.userManagement')}
        </li>

        {!isSinghealthInstance() && can(structure.role, ['export_csv'], abilities) && (
          <li className={getItemClassName('/reports')} onClick={() => openPage('/reports')}>
            <HiOutlineClipboardList className='item-icon' />
            {t('menu.report')}
          </li>
        )}
        {can(structure.role, ['export_csv'], abilities) && (
          <li className={getItemClassName('/LogsReport')} onClick={() => openPage('/LogsReport')}>
            <HiOutlineClipboardList className='item-icon' />
            {t('menu.systemlogs')}
          </li>
        )}
        {hideFeature && (
          <li className={'item'} onClick={openTelehealth}>
            <BsTelephonePlus className='item-icon' />
            {t('menu.telehealth')}
          </li>
        )}
        <li className={getItemClassName('/settings')} onClick={() => openPage('/settings')}>
          <FiSettings className='item-icon' />
          {t('menu.settings')}
        </li>
        {!isSinghealthInstance() && (
          <li className={'item'}>
            <a href={'https://ekareinchelp.zendesk.com'} target='_blank' rel='noopener noreferrer'>
              <FiHelpCircle className='item-icon' />
              {t('menu.help')}
            </a>
          </li>
        )}
        <li className={'item'} onClick={openAbout}>
          <FiInfo className='item-icon' />
          {t('menu.about')}
        </li>
        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <FiLogOut className='item-icon' />
            {t('menu.signOut')}
          </li>
        </div>
      </ul>
    </div>
  );
}

export default AccountMenu;
