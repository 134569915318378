export interface Field {
  field_id: string;
  field_name: string;
  field_values: FieldValue[];
  page: Page;
}

export interface FieldValue {
  id: string;
  value: string;
  text: string;
}

export interface Page {
  id: string;
  name: string;
}

export enum PageType {
  WOUND_CARE_PROTOCOL = 'WOUND CARE PROTOCOL',
  ADD_WOUND = 'ADD WOUND',
  ADD_TREATMENT = 'ADD TREATMENT',
}

export enum FieldType {
  SITE = 'site',
}

export enum SchemaType {
  PATIENT = 'patient',
  WOUND = 'wound',
  TREATMENT = 'treatment',
}

export enum WCPRecordType {
  CLEANSING = 'CLEANSING',
  OINTMENTS = 'OINTMENTS',
  DRESSING = 'DRESSING',
  SECONDARY_DRESSING = 'SECONDARY_DRESSING',
  ANTIINFECTIVE = 'ANTIINFECTIVE',
  OFFLOADING = 'OFFLOADING',
  NUTRITION = 'NUTRITION',
  ADVANCED = 'ADVANCED',
  REHAB = 'REHAB',
  FREQUENCY = 'FREQUENCY',
  OTHER = 'OTHER',
  OTHER_TEXT = 'OTHER_TEXT',
}

export enum TextOperation {
  ENCODE = 'encode',
  DECODE = 'decode',
}

export enum WoundStatus {
  UNLOCKED = '1',
  LOCKED = '0',
}
export enum PatientStatus {
  ENABLED = '1',
  FROZEN = '0',
}
