import ApiService from 'src/core/services/api.service';
import Parse from 'parse';

export default class ParseService {
  // LOGIN
  public static async login(username: string, password: string) {
    await Parse.User.logIn(username, password);
  }

  // GET CONVERSATION
  public static async getConversation(woundId: number, containPatient: boolean) {
    const conversationQuery = new Parse.Query(Parse.Object.extend('Conversation'));
    conversationQuery.equalTo('woundId', woundId);
    conversationQuery.equalTo('containPatient', containPatient);
    const conversation = await conversationQuery.first();
    return conversation;
  }

  // SUBSCRIBE TO CONVERSATION
  public static async subscribeToConversation(conversation: any, callback: any) {
    const query = new Parse.Query('ParseMessage');
    query.equalTo('conversation', conversation);
    const subscription = await query.subscribe();
    subscription.on('create', function (message: any) {
      callback(message);
    });
    return subscription;
  }

  // GET MESSAGES
  public static async getMessages(conversation: any) {
    const messageQuery = new Parse.Query(Parse.Object.extend('ParseMessage'));
    messageQuery.equalTo('conversation', conversation);
    messageQuery.include('sender');
    messageQuery.ascending('createdAt');
    const messages = await messageQuery.find();
    return messages.map((message: { attributes: any }) => message.attributes);
  }

  // SEND MESSAGE
  public static async sendMessage(
    userId: number,
    message: { conversation: any; body: string; codedBody: string },
    patient: { id: number; firstName: string; lastName: string; siteId: number; woundId: number; woundPrimaryLocation: number },
  ) {
    const ParseMessage = Parse.Object.extend('ParseMessage');
    const parseMessage = new ParseMessage();

    parseMessage.set('patientId', +patient.id);
    parseMessage.set('body', message.body);
    parseMessage.set('conversation', message.conversation);
    parseMessage.set('codedBody', message.codedBody);
    parseMessage.set('idClinic', +patient.siteId);
    parseMessage.set('sender_id', +userId);
    parseMessage.set('sender', Parse.User.current());
    parseMessage.set('woundId', +patient.woundId);
    parseMessage.set('woundPrimaryLocation', +patient.woundPrimaryLocation);
    parseMessage.set('patientName', patient.firstName + ', ' + patient.lastName);
    parseMessage.save(null, {
      success: function () {
        console.log('Message Sent successfully');
      },
      error: function (m: any, error: { message: string }) {
        console.log('Failed to create new object, with error code: ' + error.message);
      },
    });
  }

  // GET NOTIFICATIONS
  public static async getNotifications(siteId: number, setNotificationSeen = true) {
    const currentUser = Parse.User.current();

    if (currentUser) {
      const notificationQuery = new Parse.Query('Notification');
      notificationQuery.greaterThan('createdAt', currentUser.get('createdAt'));
      notificationQuery.equalTo('idClinic', siteId);

      const userNotificationQuery = new Parse.Query('user_notification');
      userNotificationQuery.include('notification');
      userNotificationQuery.include('notification.notificationOwner');
      userNotificationQuery.equalTo('user', currentUser);
      userNotificationQuery.matchesQuery('notification', notificationQuery);
      userNotificationQuery.descending('createdAt');
      // userNotificationQuery.notEqualTo('seen', 0);

      try {
        const notifications = await userNotificationQuery.find();
        return ParseService.prepareNotifications(notifications);
      } catch (error) {
        return null;
      }
    }

    return null;
  }

  private static prepareNotifications(notifications: any) {
    if (notifications && notifications.length > 0) {
      return notifications.map((data: any) => {
        const notification = data.attributes.notification.attributes;
        const seen = data.attributes.seen || 0;
        const object: any = {
          seen: seen,
          notificationTime: notification.createdAt,
          isEkare: false,
          woundId: notification.woundId,
          woundPrimaryLocation: notification.woundPrimaryLocation,
          patientId: notification.patientId,
          type: notification.type,
          conversation: {},
          isNotMessage: false,
          isAssignPatient: false,
          isGeneral: false,
        };

        if (notification.type === 'message' || notification.type === 'tag') {
          object.sender = notification.notificationOwner.attributes.first_name + ', ' + notification.notificationOwner.attributes.last_name;
          object.body = notification.body;
          object.patientName = notification.patientName;
          if (notification.type === 'message') {
            object.description = 'sent you a new message';
          } else if (notification.type === 'tag') {
            object.description = 'tagged you in a new message';
          }
        } else if (
          notification.type === 'assign_wound' ||
          notification.type === 'assign_patient' ||
          notification.type === 'clinician_assessment_alert' ||
          notification.type === 'clinician_assessment_alert' ||
          notification.type === 'new_measurement'
        ) {
          object.body = notification.body;
          object.patientName = notification.patientName;
          object.isNotMessage = true;
        } else if (notification.type === 'general') {
          object.body = notification.body;
          object.title = notification.title;
          object.isGeneral = true;
          object.isNotMessage = true;
        } else if (notification.type === 'ekare') {
          object.body = notification.body;
          object.title = notification.title;
          object.isEkare = true;
        }

        return object;
      });
    } else {
      return [];
    }
  }

  // SUBSCRIBE TO NOTIFICATION
  public static async subscribeToNotification(siteId: number, callback: any) {
    const currentUser = Parse.User.current();
    if (currentUser) {
      const notificationQuery = new Parse.Query('notification');
      notificationQuery.greaterThan('createdAt', currentUser.get('createdAt'));
      notificationQuery.equalTo('idClinic', siteId);
      const userNotificationQuery = new Parse.Query('user_notification');
      userNotificationQuery.include('notification');
      userNotificationQuery.include('notification.notificationOwner');
      userNotificationQuery.equalTo('user', currentUser);
      userNotificationQuery.matchesQuery('notification', notificationQuery);
      userNotificationQuery.descending('createdAt');
      const subscription = await userNotificationQuery.subscribe();
      subscription.on('create', function (notification: any) {
        callback(notification);
      });
    }
  }

  public static async setNotificationsSeen(siteId: number) {
    const currentUser = Parse.User.current();

    if (currentUser) {
      const notificationQuery = new Parse.Query('Notification');
      notificationQuery.greaterThan('createdAt', currentUser.get('createdAt'));
      notificationQuery.equalTo('idClinic', siteId);
      const userNotificationQuery = new Parse.Query('user_notification');
      userNotificationQuery.include('notification');
      userNotificationQuery.include('notification.notificationOwner');
      userNotificationQuery.equalTo('user', currentUser);
      userNotificationQuery.matchesQuery('notification', notificationQuery);
      userNotificationQuery.descending('createdAt');
      userNotificationQuery.notEqualTo('seen', 1);
      //let notifications = await userNotificationQuery.find().then((item: any) => item.map((item: any, index: any) => item.attributes.notification.attributes));
      userNotificationQuery.find().then((item: any) =>
        item.map((data: any, index: any) => {
          data.set('seen', 1);
          data.save();
        }),
      );
    }
  }
}
