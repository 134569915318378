import './sideMenu.style.scss';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { useTranslation } from 'react-i18next';
import { BiArrowBack, BiHome } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FiUsers, FiSettings, FiHelpCircle, FiLogOut, FiInfo } from 'react-icons/fi';
import { BsTelephonePlus } from 'react-icons/bs';
import { can, hideFeature } from 'src/core/utils/global.utils';
import { isSinghealthInstance } from 'src/core/utils/instance.utils';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
  openTelehealth: any;
  openAbout: any;
}

function SiteMenu(props: Props) {
  const { structure, user, setStructure, abilities, selectedAccount, setSelectedAccount } = useContext(AppContext);
  const { openPage, signOut, getItemClassName, openTelehealth, openAbout } = props;
  const { t } = useTranslation();

  const BackToSites = () => {
    if (user.structures && user.structures.length > 0) {
      const updatedStructure: any = {
        ...user.structures[0],
        account_wide_access: structure.account_wide_access,
      };
      setStructure(updatedStructure);
      openPage('/sites');
    }
  };
  return (
    <div className='side-menu'>
      <ul className='items'>
        {user.structures && can(user.structures[0].role, ['view_sites'], abilities) && (
          <li className={getItemClassName('/sites')} onClick={BackToSites} style={{ width: 214, marginLeft: 10 }}>
            <BiArrowBack className='item-icon' />
            {t('account.accountAdministration')}
          </li>
        )}

        {hideFeature && (
          <li className={getItemClassName('/dashboard')} onClick={() => openPage('/dashboard')}>
            <BiHome className='item-icon' />
            {t('account.dashboard')}
          </li>
        )}
        <li className={getItemClassName('/patients')} onClick={() => openPage('/patients')}>
          <HiOutlineClipboardList className='item-icon' />
          {t('menu.patients')}
        </li>

        <li className={getItemClassName('/users')} onClick={() => openPage('/users')}>
          <FiUsers className='item-icon' />
          {t('menu.userManagement')}
        </li>
        {user.structures && can(user.structures[0].role, ['access_sites'], abilities) && (
          <li className={getItemClassName('/sites')} onClick={() => openPage('/sites')}>
            <BiHome className='item-icon' />
            {t('menu.sites')}
          </li>
        )}
        {!isSinghealthInstance() && can(structure.role, ['export_csv'], abilities) && (
          <li className={getItemClassName('/reports')} onClick={() => openPage('/reports')}>
            <HiOutlineClipboardList className='item-icon' />
            {t('menu.report')}
          </li>
        )}
        {hideFeature && (
          <li className={'item'} onClick={openTelehealth}>
            <BsTelephonePlus className='item-icon' />
            {t('menu.telehealth')}
          </li>
        )}
        <li className={getItemClassName('/settings')} onClick={() => openPage('/settings')}>
          <FiSettings className='item-icon' />
          {t('menu.settings')}
        </li>
        {!isSinghealthInstance() && (
          <li className={'item'}>
            <a href={'https://ekareinchelp.zendesk.com'} target='_blank' rel='noopener noreferrer'>
              <FiHelpCircle className='item-icon' />
              {t('menu.help')}
            </a>
          </li>
        )}
        <li className={getItemClassName('/about')} onClick={openAbout}>
          <FiInfo className='item-icon' />
          {t('menu.about')}
        </li>
        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <FiLogOut className='item-icon' />
            {t('menu.signOut')}
          </li>
        </div>
      </ul>
    </div>
  );
}

export default SiteMenu;
