import ApiService from 'src/core/services/api.service';
import { GetUsersReq, User } from 'src/core/models/user.models';

export default class UserService extends ApiService {
  public static getAbilities(): Promise<any> {
    return this.apiGet('/abilities');
  }

  public static getUsers(params: GetUsersReq): Promise<any> {
    return this.apiGet('/users', params);
  }
  public static getGroupUsers(params: GetUsersReq): Promise<any> {
    return this.apiGet('/users', params);
  }
  public static async updateUser(data: User) {
    try {
      return await this.apiPut(`/users/${data.id}`, {
        id: data.id,
        user: data,
      });
    } catch (error) {
      return error;
    }
  }
  public static async AddUser(data: any) {
    try {
      return await this.apiPost(`/users`, data);
    } catch (error) {
      return error;
    }
  }
  public static async linkUser(data: any) {
    try {
      return await this.apiPost(`/users/link`, data);
    } catch (error) {
      return error;
    }
  }
  public static async inviteUser(id: any) {
    try {
      return await this.apiPost(`/users/invite/${id}`);
    } catch (error) {
      return error;
    }
  }
  public static async ResetUserPwd(email: string, username: string) {
    try {
      return await this.apiPost(`/reset/admin`, {
        data: {
          email: email,
          username: username,
        },
      });
    } catch (error) {
      return error;
    }
  }
  public static async updateUserPwd(data: any) {
    try {
      return await this.apiPut(`/users/${data.id}`, {
        action: 'update-password',
        id: data.id,
        user: data,
      });
    } catch (error) {
      return error;
    }
  }

  public static async getClinicians(siteId: number): Promise<User[]> {
    const params: GetUsersReq = {
      clinic: siteId,
      activeOnly: 1,
      patientBased: 1,
      status: 1,
      flag: 1,
      exclude_patient_users: 1,
    };

    try {
      return await this.apiGet('/users', params);
    } catch (error) {
      return [];
    }
  }
  public static async deleteUsers(clinic_id_clinic: number, id: any) {
    try {
      return await this.apiDelete(`/users/${id}`, {
        id: id,
        clinic_id_clinic: clinic_id_clinic,
        relations: '1',
      });
    } catch (error) {
      return error;
    }
  }
  public static async deleteGroupUsers(id: any) {
    try {
      return await this.apiDelete(`/users/${id}`, {
        id: id,
        relations: '1',
      });
    } catch (error) {
      return error;
    }
  }
  public static async addAccountUsers(id: number | string, users: any) {
    try {
      return await this.apiPost(`/structures/${id}/account`, { users: users });
    } catch (error) {
      return error;
    }
  }
  public static async addSitesToUsers(id: number | string, sites: any) {
    try {
      return await this.apiPost(`/structures/${id}/users`, { sites: sites });
    } catch (error) {
      return error;
    }
  }
  public static getAccountUsers(id: number): Promise<any> {
    return this.apiGet(`/structures/${id}/users`);
  }
}
