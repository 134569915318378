import { CSSProperties } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/assets/styles/theme.style';
interface ComponentProps {
  className?: string;
  style?: CSSProperties;
}

export const PageLoading = (props: ComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className={props.className} style={{ ...style.component }}>
      <div style={{ ...style.spinner }}>
        <Spinner animation='border' variant='light' />
        <p className='mt-3 text-light'>{t('common.pleaseWait')}</p>
      </div>
    </div>
  );
};

export const ComponentLoading = (props: ComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className={'text-center data-spinner ' + props.className} style={props.style}>
      <Spinner animation='border' variant={colors.primaryColor} />
      <p className='mt-3 text-secondary'>{t('common.pleaseWait')}</p>
    </div>
  );
};

export const TableLoading = (props: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className={props.className} style={{ ...style.tabcomponent }}>
      <div style={{ ...style.spinner }}>
        <Spinner animation='border' variant='' />
        <p className='mt-3 text-secondary'>{t('common.pleaseWait')}</p>
      </div>
    </div>
  );
};

const style: { [style: string]: CSSProperties } = {
  component: {
    backgroundColor: '#000000',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'fixed',
    display: 'block',
    opacity: '0.7',
    zIndex: 999,
    textAlign: 'center',
  },
  spinner: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    zIndex: 100,
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
  },

  tabcomponent: {
    backgroundColor: '#fff',
    width: '79%',
    height: '71%',
    position: 'fixed',
    display: 'block',
    opacity: '0.7',
    textAlign: 'center',
    right: '3%',
    borderRadius: 20,
  },
};
